

html {
    font-size: 14px;
}

body {
    font-family: var(--font-family);
    font-weight: normal;
    background: var(--surface-ground);
    color: var(--text-color);
    padding: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.ui-dialog {
    z-index: 1050; /* Asegúrate de que el valor es suficientemente alto */
}

.alert-success {
    position: fixed; /* O absolute, según el diseño */
    z-index: 1100; /* Debe ser mayor que el z-index del modal */
    /* Otros estilos para la alerta */
}
